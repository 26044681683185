<template>
	<div class="header" :style="{background:subjectColor}">
		<div class="logo">
			<img :src="logo" height="30" width="30" />
			<span v-if="!collapse">{{title}}</span>
		</div>
		<!-- <div class="collapse-btn" @click="handleCollapseChange">
      <i v-if="!collapse" class="el-icon-s-fold" style="color:#C0CBCC"></i>
      <i v-else class="el-icon-s-unfold" style="color:#C0CBCC"></i>
    </div> -->
		<div class="header-right">
			<div class="docList">
				<span class="mpDoc" @click="openDoc(2)" v-if="companyRole.includes('码客通')"><img
						src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/zl1%402x.png" />码客通名片</span>
				<span class="scrmDoc" @click="openDoc(1)" v-if="companyRole.includes('码客通')"><img
						src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/zl2%402x.png" />码客通SCRM</span>
				<span class="pcDoc" @click="openDoc(3)"
					v-if="companyRole.includes('码客通') || companyRole.includes('码客查（不含商城）') || companyRole.includes('码客查（含商城）')"><img
						src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/zl3%402x.png" />PC端</span>
				<span class="xjmDoc" v-if="checkPermission(['rytStatistics'])" @click="openDoc(4)"><img
						src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/zl4%402x.png" />设备巡检码</span>
				<span class="fchDoc" @click="openDoc(5)"
					v-if=" companyRole.includes('出入库系统') && userInfo.userType!== 3 && userInfo.userType!== 4"><img
						src="https://mzmpic.oss-cn-beijing.aliyuncs.com/scIcon.png" />防窜货系统</span>
			</div>
			<div class="header-user-con">
				<!-- <div class="btn-fullscreen" @click="handleFullScreen">
          <el-tooltip effect="dark" :content="fullScreen?`取消全屏`:`全屏`" placement="bottom">
            <i class="el-icon-rank cblue"></i>
          </el-tooltip>
        </div> -->
				<div class="user-avator" v-if="userInfo.avatarUrl">
					<img :src="userInfo.avatarUrl" />
				</div>
				<el-dropdown class="user-name" trigger="click" @command="handleCommand">
					<span class="el-dropdown-link" style="color:rgba(0, 0, 0, 0.65);">
						{{ name }}
						<!-- {{( userInfo && userInfo.userName? userInfo.userName + '-' : '') }} -->
						{{ getRole(userInfo.userType)}}
						<i class="el-icon-arrow-down"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item divided command="gover">更新日志</el-dropdown-item>
						<el-dropdown-item divided command="logout">退出登录</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
		<!-- <div v-if="isScrmPhone == 'true'" style="color: rgba(0, 0, 0, 0.65);float: right;line-height: 48px;cursor: pointer;font-size: 14px;margin-right: 20px;display: flex;align-items: center;" @click="getAll">
		<span>切换公司</span>
		<img style="width: 20px;height: 20px;margin-left: 10px;" src="../../assets/images/switchC.png" />

	</div> -->
		<el-dialog :modal-append-to-body="false" title="公司列表" :visible.sync="dialogTableVisible"
			:close-on-click-modal="false">
			<el-table ref="table" :data="compantyList" border style="" @selection-change="handleSelectionChange">
				<el-table-column class="selectALL" type="selection" width="55">
				</el-table-column>
				<el-table-column prop="companyName" label="公司名称" width="380" align="center">
				</el-table-column>
				<el-table-column prop="name" label="姓名" align="center">
				</el-table-column>
				<el-table-column prop="phone" label="电话" align="center">
				</el-table-column>
			</el-table>
			<div class="page">
				<el-pagination @current-change="handleMACIDChange" :current-page.sync="pageNum" :page-sizes="[10]"
					:page-size="10" layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="cancel()">
					取消
				</el-button>
				<el-button type="primary" @click="save">
					确定
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import bus from "../../service/bus";
	import utils from "../../utils/index";
	import {
		queryOemDomain
	} from "@/api/oemConfig.js";
	import {
		getAllCompany,
		switchCompanty,
		fetchCompanyRole
	} from "@/api/companyManage.js";
	import store from "@/store";
	import {
		checkPermission
	} from "@/directives/permission";
	export default {
		name: "commonHeader",
		data() {
			return {
				companyRole: [],
				dialogTableVisible: false,
				collapse: false,
				fullScreen: false,
				userInfo: this.$store.state.loginRoot.userInfo,
				title: '',
				logo: '',
				icon: '',
				compantyList: [],
				pageNum: 1,
				pageSize: 10,
				total: 0,
				comInfo: {
					companyId: '',
					phone: ''
				},
				isScrmPhone: null,
				name: '',
			};
		},
		async created() {
			this.isScrmPhone = localStorage.getItem('isScrmPhone');
			if (document.domain != "localhost" && document.domain != "crm.86122m.com") {
				const oemConfig = await queryOemDomain({
					oemDomain: document.domain
				});
				if (!oemConfig) {
					this.$message.error('oem配置错误,请联系售后');
					return;
				}
				this.setOemConfig(oemConfig);
			} else {
				this.setOemConfig();
			}
			if (this.$store.state.loginRoot.userInfo.username) {
				this.name = this.$store.state.loginRoot.userInfo.username + '-'
			} else if (this.$store.state.loginRoot.userInfo.nickName) {
				this.name = this.$store.state.loginRoot.userInfo.nickName + '-'
			} else {
				this.name = ''
			}

			// 获取角色
			fetchCompanyRole().then(res => {
				this.companyRole = res.data.map(item => item.name)
			})
		},
		computed: {
			// 主题颜色
			subjectColor() {
				return this.$store.state.subjectColor
			}
		},
		methods: {
			checkPermission,
			handleSelectionChange(rows) {
				if (rows.length > 1) {
					this.$refs.table.clearSelection()
					this.$refs.table.toggleRowSelection(rows.pop())
				} else {
					if (rows.length == 1) {
						this.comInfo = {
							companyId: rows[0].companyId,
							phone: rows[0].phone
						}
					}
				}
				console.log(this.comInfo)
			},
			cancel() {
				this.dialogTableVisible = false;
			},
			save() {
				switchCompanty(this.comInfo).then(res => {
					console.log(res)
					if (res.code == 200) {
						this.$store.state.loginRoot.loginDate = res.data.lastLoginTime
						this.$store.state.loginRoot.userInfo = res.data;
						localStorage.setItem("info", JSON.stringify(res.data));
						localStorage.setItem("loginDate", res.data.lastLoginTime);
						//存储token
						localStorage.setItem("token", res.data.token);
						// setLocalStorage('info', JSON.stringify(res.data))
						this.dialogTableVisible = false;
						this.$message({
							type: 'success',
							message: '切换成功'
						});
						setTimeout(() => {
							location.reload();
						}, 1000)
					}
				})
			},
			handleMACIDChange() {
				this.getAll();
			},
			getAll() {
				this.dialogTableVisible = true
				getAllCompany({
					pageNum: this.pageNum,
					pageSize: 10
				}).then(res => {
					if (res.code == 200) {
						this.compantyList = res.data.list
						this.total = res.data.total
					}
					console.log(res)
				})
			},
			setOemConfig(oemConfig) {
				console.log(oemConfig)
				//查询所有Oem配置 crm.86122m.com localhost 本地调试
				console.log("domain:", document.domain);
				//获取icon
				var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
				link.type = 'image/x-icon';
				link.rel = 'shortcut icon';
				// if(oemConfig){
				//   link.href = oemConfig.data.iconLogo? oemConfig.data.iconLogo : "http://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/icon/favicon.ico";
				//   document.getElementsByTagName('head')[0].appendChild(link);
				//   //获取网页标题
				//   document.title = oemConfig.data.oemName? oemConfig.data.oemName : '码客通后台';
				//   this.title = oemConfig.data.oemName? oemConfig.data.oemName : '码客通后台';
				//   this.logo = oemConfig.data.oemLogo? oemConfig.data.oemLogo : "https://mzmpic.oss-cn-beijing.aliyuncs.com/scrm_logo_small.png";
				// }else{
				var address = window.location.host;
				if (address.startsWith("scrm.86122m.com") || address.startsWith(
						"localhost")) {
					// link.href = "https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/icon/favicon.ico";
					link.href = "https://mzmpic.oss-cn-beijing.aliyuncs.com/zxNemLogo.ico";
					document.getElementsByTagName('head')[0].appendChild(link);
					//获取网页标题
					document.title = "涿溪一码通";
					this.title = "涿溪一码通";
					this.logo = "https://mzmpic.oss-cn-beijing.aliyuncs.com/zxNewLogo.jpg";
				} else if (address.startsWith("pdm.86122m.com")) {
					link.href = "https://mzmpic.oss-cn-beijing.aliyuncs.com/zxNemLogo.ico";
					document.getElementsByTagName('head')[0].appendChild(link);
					//获取网页标题
					document.title = "涿溪PDM产品数据管理系统";
					this.title = "涿溪PDM产品数据管理系统";
					this.logo = "https://mzmpic.oss-cn-beijing.aliyuncs.com/zxNewLogo.jpg";
				} else if (address.startsWith("plm.86122m.com")) {
					link.href = "https://mzmpic.oss-cn-beijing.aliyuncs.com/zxNemLogo.ico";
					document.getElementsByTagName('head')[0].appendChild(link);
					//获取网页标题
					document.title = "涿溪PLM产品生命周期管理系统";
					this.title = "涿溪PLM产品生命周期管理系统";
					this.logo = "https://mzmpic.oss-cn-beijing.aliyuncs.com/zxNewLogo.jpg";
				} else if (address.startsWith("wms.86122m.com")) {
					link.href = "https://mzmpic.oss-cn-beijing.aliyuncs.com/zxNemLogo.ico";
					document.getElementsByTagName('head')[0].appendChild(link);
					//获取网页标题
					document.title = "涿溪WMS仓储管理系统";
					this.title = "涿溪WMS仓储管理系统";
					this.logo = "https://mzmpic.oss-cn-beijing.aliyuncs.com/zxNewLogo.jpg";
				} else if (address.startsWith("crm.86122m.com")) {
					link.href = "https://mzmpic.oss-cn-beijing.aliyuncs.com/zxNemLogo.ico";
					document.getElementsByTagName('head')[0].appendChild(link);
					//获取网页标题
					document.title = "涿溪CRM客户关系管理系统";
					this.title = "涿溪CRM客户关系管理系统";
					this.logo = "https://mzmpic.oss-cn-beijing.aliyuncs.com/zxNewLogo.jpg";
				} else if (address.startsWith("fyzsm.idshanxi.com")) { // fyzsm.cqm.cn
					link.href = "https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/icon/fyIcon.ico";
					document.getElementsByTagName('head')[0].appendChild(link);
					//获取网页标题
					document.title = "方圆证书码系统";
					this.logo = "https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/icon/fyLogo.jpg"
					this.title = "方圆证书码系统";
				} else if (address.startsWith("fycpm.idshanxi.com")) { // fycpm.cqm.cn
					link.href = "https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/icon/fyIcon.ico"
					document.getElementsByTagName('head')[0].appendChild(link)
					document.title = "方圆产品追溯系统"
					this.logo = "https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/icon/fyLogo.jpg"
					this.title = "方圆产品追溯系统"
				} else if (address.startsWith("fymp.86122m.com")) { // fycpm.cqm.cn
					link.href = "https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/icon/fyIcon.ico"
					document.getElementsByTagName('head')[0].appendChild(link)
					document.title = "基于标识的产品铭牌管理系统"
					this.logo = "https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/icon/fyLogo.jpg"
					this.title = "基于标识的产品铭牌管理系统"
				} else if (address.startsWith("fyxj.86122m.com")) { // fycpm.cqm.cn
					link.href = "https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/icon/fyIcon.ico"
					document.getElementsByTagName('head')[0].appendChild(link)
					document.title = "基于标识的巡检系统"
					this.logo = "https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/icon/fyLogo.jpg"
					this.title = "基于标识的巡检系统"
				} else if (address.startsWith("fycrk.86122m.com")) { // fycpm.cqm.cn
					link.href = "https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/icon/fyIcon.ico"
					document.getElementsByTagName('head')[0].appendChild(link)
					document.title = "基于标识的产品快速出入库系统"
					this.logo = "https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/icon/fyLogo.jpg"
					this.title = "基于标识的产品快速出入库系统"
				} else if (address.startsWith("fyfch.86122m.com")) { // fycpm.cqm.cn
					link.href = "https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/icon/fyIcon.ico"
					document.getElementsByTagName('head')[0].appendChild(link)
					document.title = "基于标识的产品防窜货管理系统"
					this.logo = "https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/icon/fyLogo.jpg"
					this.title = "基于标识的产品防窜货管理系统"
				} else {
					link.href = "https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/icon/gbylogo.ico"
					document.getElementsByTagName('head')[0].appendChild(link)
					//获取网页标题
					document.title = "广州标准化研究院"
					this.title = "广州标准化研究院"
					this.logo = "https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/icon/gbylogo.png"
				}

				// }
			},
			// 全屏
			handleFullScreen() {
				let element = document.documentElement;
				if (this.fullScreen) {
					if (document.exitFullscreen) {
						document.exitFullscreen();
					} else if (document.webkitCancelFullScreen) {
						document.webkitCancelFullScreen();
					} else if (document.webkitCancelFullScreen) {
						document.webkitCancelFullScreen();
					} else if (document.msExitFullscreen) {
						document.msExitFullscreen();
					}
				} else {
					if (element.requestFullscreen) {
						element.requestFullscreen();
					} else if (element.webkitRequestFullScreen) {
						element.webkitRequestFullScreen();
					} else if (element.msRequestFullscreen) {
						element.msRequestFullscreen();
					}
				}
				this.fullScreen = !this.fullScreen;
			},

			// 下拉菜单选择
			handleCommand(commond) {
				if (commond === "logout") {
					store.dispatch("setHasRole", false)
					utils.clearLoginInfo()
				} else if (commond === "gover") {
					this.$router.push({
						path: '/verManage'
					})
				}
			},

			// 控制折叠面板
			handleCollapseChange() {
				this.collapse = !this.collapse;
				bus.$emit("collapse", this.collapse);
			},
			getRole(userType) {
				let str = ''
				if (userType == 1) {
					str = '管理员'
				} else if (userType == 2) {
					str = '超级管理员'
				} else {
					str = '成员'
				}
				return str
			},

			// 打开文档
			openDoc(num) {
				if (num == 1) {
					window.open("https://pqoudte1djo.feishu.cn/docx/KUuBdYFALoyFLkxAYLwcAgiTnxh?from=from_copylink",
						"_blank");
				} else if (num == 2) {
					window.open("https://pqoudte1djo.feishu.cn/docx/Kbavdx4JIoQjH7xEXXvcfdPEnss?from=from_copylink",
						"_blank");
				} else if (num == 3) {
					window.open("https://pqoudte1djo.feishu.cn/docx/Io0bd5OTXoBjWzxJBV4cYigInN6?from=from_copylink",
						"_blank");
				} else if (num == 4) {
					window.open("https://pqoudte1djo.feishu.cn/docx/ZksZdm9FGoF0Z2xLleNc4hlynnh?from=from_copylink",
						"_blank");
				} else if (num == 5) {
					window.open("https://pqoudte1djo.feishu.cn/docx/A0kCdXbqPoxb3JxkiVZcfTWgnEc?from=from_copylink",
						"_blank");
				}
			}
		},
	};
</script>

<style scoped>
	.header {
		position: relative;
		box-sizing: border-box;
		width: 100%;
		height: 48px;
		font-size: 22px;
		background-color: #6190e8;
		color: #ffffff;
		box-shadow: 0px 2px 6px rgba(115, 127, 128, 0.12);
		z-index: 2;
		/* border-bottom: 1px solid #eee; */
	}

	.collapse-btn {
		float: left;
		/* padding: 0 21px; */
		cursor: pointer;
		line-height: 48px;
		color: #ffffff;
		margin-left: 10px;
	}

	.header .logo {
		float: left;
		line-height: 48px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		color: #ffffff;
		margin-left: 25px;
	}

	.header .logo img {
		vertical-align: middle;
		margin-right: 8px;
	}

	.header .logo span {
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 700;
		color: #333;
	}

	.header-right {
		float: right;
		padding-right: 20px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.header-user-con {
		display: flex;
		height: 48px;
		align-items: center;
	}

	.btn-fullscreen {
		transform: rotate(45deg);
		margin-right: 5px;
		font-size: 24px;
	}

	.btn-bell,
	.btn-fullscreen {
		position: relative;
		width: 30px;
		height: 30px;
		text-align: center;
		border-radius: 15px;
		cursor: pointer;
	}

	.btn-bell-badge {
		position: absolute;
		right: 0;
		top: -2px;
		width: 8px;
		height: 8px;
		border-radius: 4px;
		background: #f56c6c;
		color: #999;
	}

	.btn-bell .el-icon-bell {
		color: #999;
	}

	.user-name {
		margin-left: 10px;
	}

	.user-avator {
		margin-left: 10px;
	}

	.user-avator img {
		display: block;
		width: 40px;
		height: 40px;
		border-radius: 50%;
	}

	.el-dropdown-link {
		color: #ffffff;
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.el-dropdown-menu__item {
		text-align: center;
	}

	.page {
		padding: 10px 15px;
		text-align: center;
	}

	/deep/thead .el-table-column--selection .cell {
		display: none;
	}

	.docList {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #474748;
		vertical-align: middle;
	}

	.docList span {
		padding: 8px 9px;
		border-radius: 4px;
		margin-right: 10px;
	}

	.docList img {
		width: 19px;
		height: 18px;
		vertical-align: middle;
		margin-right: 12px;
	}

	.scrmDoc {
		background-color: #EDF3FE;
		cursor: pointer;
	}

	.mpDoc {
		background-color: #E3F4EC;
		cursor: pointer;
	}

	.pcDoc {
		background-color: #F9F4FE;
		cursor: pointer;
	}

	.xjmDoc {
		background-color: #FEF9F4;
		cursor: pointer;
	}

	.fchDoc {
		background-color: #fff3ef;
		cursor: pointer;
	}
</style>