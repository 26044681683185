<template>
    <div v-if="!menus.hidden">
      <el-submenu :index="resolvePath(menus.path)" v-if="menus.children&&!menus.meta.hideParent">
        <template slot="title" >
          <svg class="icon" aria-hidden="true" style="margin-right:12px; font-size: 18px">
            <use :xlink:href="menus.meta.icon" v-if="menus.meta.icon"/>
          </svg>
          <span slot="title" class="title">{{ menus.meta.title }}</span>
        </template>
          <layout-menus v-for="i in menus.children" :key="i.path" :menus="i" :base-path="resolveSubPath(i)"/>
      </el-submenu>

      <layout-menus :menus="menus.children[0]" :base-path="resolveSubPath(menus.children[0])" v-else-if="menus.children && menus.children.length===1&&menus.meta.hideParent"/>
      <el-menu-item :index="resolvePath(menus.path)" v-else>
          <svg class="icon" aria-hidden="true" style="margin-right:12px; font-size: 18px" v-if="menus.meta.icon">
            <use :xlink:href="menus.meta.icon" />
          </svg>
           {{ menus.meta.title }}
      </el-menu-item>
    </div>
</template>
<script>

import path from 'path'

export default {
  name: "LayoutMenus",
  props: {
    menus: {
      type: Object,
      require: true,
      default: () => {}
    },
    basePath:{

    }
  },
  methods: {
    // menuClick(data) {
    //   localStorage.setItem('curMenu', JSON.stringify(data))
    // }
    resolveSubPath(item){
      if(item.children){
        return path.resolve(this.basePath, item.path)
      }else {
        return  this.basePath
      }
    },
    resolvePath(routePath) {
      return path.resolve(this.basePath, routePath)
    }
  }

}
</script>