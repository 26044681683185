<template>
  <div>
  <div class="sidebar">
    <el-menu
      :default-active="onRoute"
      class="sidebar-el-menu"
      :collapse="collapse"
      :background-color="subjectColor"
      text-color="#17191A"
      active-text-color="#51CBCD"
      :collapse-transition="false"
      router
      unique-opened
    >
      <c-menus v-for="i in items" :key="i.index" :menus="i" :basePath="i.path"/>
    </el-menu>
  </div>
  <div class="bbh">版本：V2.0.1</div>
  </div>
</template>

<script>
import {
		fetchMenuList, fetchTabsList
  } from '@/api/login'

import Roots from '@/utils/root.js'
import CMenus from './menus.vue'
import bus from "../../service/bus";
import {mapGetters} from "vuex";
import {asyncRouters, constRouter} from "@/router";
export default {
  name: "commonSidebar",
  data() {
    return {
      items:[],
      collapse: false
    };
  },
  created() {
    // 控制折叠面板
    bus.$on("collapse", (msg) => {
      this.collapse = msg;
      bus.$emit("collapse-content", msg);
    });
  },
  components: { CMenus },

  computed: {
    ...mapGetters([
      'asyncMenus'
    ]),
    // 主题颜色
    subjectColor() {
      return this.$store.state.subjectColor;
    },
    // 路由配置
    onRoute() {
      return this.$route.path;
    },

  },
  mounted() {
    console.log('动态路由',this.asyncMenus)
    this.$set(this,'items',constRouter.concat(this.asyncMenus))
  },
  watch:{

  },
  methods:{

  }
};
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  width: 208px;
  left: 0;
  top: 68px;
  bottom: 40px;
  overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
  display:none;
}
.sidebar-el-menu:not(.el-menu--collapse) {
  width: 208px;
}

.sidebar > ul {
  height: 100%;
}
.sidebar .el-menu{
  border-right:none;
}
.title {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* .el-menu-item {
  border-left: #fff solid 6px;
} */
/* 设置鼠标悬停时el-menu-item的样式 */
/* .el-menu-item:hover {
  border-left: #6190e8 solid 6px !important;
  background-color: #e2eff9 !important;
  color: #6190e8 !important;
} */

/* .el-menu-item:hover i {
  color: #6190e8;
} */

/* 设置选中el-menu-item时的样式 */
.el-menu-item,
.el-submenu {
  /* opacity: 0.8; */
}
.el-menu-item .is-active {
  opacity: 1;
}
.memberRoot{
  display: none;
}
.bbh{
  color: #C0C4CC;
  font-size: 14px;
  text-align: center;
  position: absolute;
  left:0;
  bottom: 0;
  height: 40px;
  width: 208px;
  line-height: 40px;
}
</style>
